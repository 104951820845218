import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { getInitialTheme, ThemeContext } from './contexts/theme.context';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import { MainPage } from './components/MainPage';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#16201A',
    },
    primary: {
      main: '#ab1c23',
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#ab1c23',
        },
      },
    },
  },
});

const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#ab1c23',
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#16201A',
          color: 'white',
        },
      },
    },
  },
});

const router = createBrowserRouter([
  {
    path: '/:lang/',
    element: <MainPage />,
  },
  {
    path: '*',
    element: <Navigate to="/en/" replace={true} />,
  },
]);

function App() {
  const [theme, setTheme] = useState<'dark' | 'light'>(getInitialTheme());

  const handleThemeToggle = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    localStorage.setItem('theme', newTheme);
    setTheme(newTheme);
  };

  return (
    <ThemeProvider
      theme={createTheme(theme === 'dark' ? darkTheme : lightTheme)}
    >
      <CssBaseline />
      <ThemeContext.Provider
        value={{
          theme,
          onToggle: handleThemeToggle,
        }}
      >
        <RouterProvider router={router} />
      </ThemeContext.Provider>
    </ThemeProvider>
  );
}

export default App;
