import { Grid } from '@mui/material';
import ServicesImage from '../../assets/images/Services.jpg';
import * as React from 'react';
import ServicesAccordion from './Accordion';

export const ServicesList = () => {
  return (
    <Grid container sx={{ height: '100%' }} spacing={1}>
      <Grid item sx={{ height: 'auto', width: '100%' }} md={6}>
        <ServicesAccordion />
      </Grid>
      <Grid item md={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
        <img
          src={ServicesImage}
          alt="Services"
          loading="lazy"
          height="auto"
          width="100%"
        />
      </Grid>
    </Grid>
  );
};
