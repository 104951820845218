import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import AboutMeImage from '../assets/images/AboutMe.jpg';
import { useTranslation } from 'react-i18next';
import React from 'react';

export const AboutMe = () => {
  const { t } = useTranslation();
  const imageItem = {
    src: AboutMeImage,
    title: 'AboutMe',
    rows: 1,
    cols: 1,
  };
  return (
    <Grid container spacing={1}>
      <Grid item md={6} xs={12}>
        <img
          src={AboutMeImage}
          alt={imageItem.title}
          loading="lazy"
          height="auto"
          width="100%"
        />
      </Grid>
      <Grid item md={6} xs={12} sx={{ paddingTop: { xs: 0, sm: '3em' } }}>
        <Typography variant="h6" sx={{ fontStyle: 'italic' }}>
          "{t('aboutMe.description')}"
        </Typography>
      </Grid>
    </Grid>
  );
};
