import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography';
import { Facebook, Instagram, Twitter } from '@mui/icons-material';
import { Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import Button from '@mui/material/Button/Button';
import { useTranslation } from 'react-i18next';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const ContactMeForm = () => {
  const { t } = useTranslation();
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const onCaptcha = () => setSubmitDisabled(false);

  return (
    <form action="https://submit-form.com/JraGtBCYH">
      <Box flexDirection="column" display="flex" sx={{ width: '100%', gap: 1 }}>
        <TextField
          label={t('contactMe.form.name')}
          name="name"
          variant="outlined"
          required
        />
        <TextField
          label={t('contactMe.form.email')}
          name="email"
          variant="outlined"
          required
        />
        <TextField
          label={t('contactMe.form.phone')}
          name="phone"
          variant="outlined"
          required
        />
        <TextField
          name="text"
          label={t('contactMe.form.text')}
          variant="outlined"
          required
          multiline
        />
        <HCaptcha
          sitekey="11b97226-ef3a-4d1a-ab17-7f0878b35e34"
          onVerify={(token, ekey) => onCaptcha()}
        />
        <Button type="submit" variant="contained" disabled={submitDisabled}>
          {t('contactMe.form.buttonTitle')}
        </Button>
      </Box>
    </form>
  );
};

export const ContactMe = () => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1}>
      <Grid item md={6} xs={12}>
        <Typography>{t('contactMe.contacts')}</Typography>
        <Instagram />
        <Facebook />
        <Twitter />
      </Grid>
      <Grid item md={6} xs={12}>
        <ContactMeForm />
      </Grid>
    </Grid>
  );
};
