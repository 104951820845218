import { createContext } from 'react';

export const getInitialTheme = (): 'dark' | 'light' => {
  const localStorageTheme = localStorage.getItem('theme');
  if (!localStorageTheme)
    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
  return localStorageTheme as 'dark' | 'light';
};

export const ThemeContext = createContext({
  theme: 'dark',
  onToggle: () => {},
});
