import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from './translations.json';

export const i18n = i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    supportedLngs: ['en', 'pl', 'ru', 'ua'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
