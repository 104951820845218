import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

export default function LanguageMenu() {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { lang } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(lang).catch(console.error);
  }, [lang]);

  const handleLanguage = (language: string) => () => {
    setAnchorEl(null);
    navigate(`/${language}`);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          color: 'white',
        }}
      >
        {i18n.language}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleLanguage}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleLanguage('ua')}>UA</MenuItem>
        <MenuItem onClick={handleLanguage('en')}>EN</MenuItem>
        <MenuItem onClick={handleLanguage('pl')}>PL</MenuItem>
        <MenuItem onClick={handleLanguage('ru')}>RU</MenuItem>
      </Menu>
    </div>
  );
}
