import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { Suspense, useEffect } from 'react';
import Container from '@mui/material/Container/Container';
import DrawerAppBar from './NavBar';
import Toolbar from '@mui/material/Toolbar/Toolbar';
import Box from '@mui/material/Box/Box';
import { MainImage } from './MainImage';
import { AboutMe } from './AboutMe';
import Typography from '@mui/material/Typography';
import { ServicesList } from './services/ServicesList';
import Gallery from './Gallery';
import { ContactMe } from './ContactMe';

export const MainPage = () => {
  const { hash } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (!hash) return;
    document.getElementById(hash)?.scrollIntoView();
  }, [hash]);

  return (
    <Suspense fallback={<>loading</>}>
      <Container disableGutters>
        <DrawerAppBar />
        <Toolbar />
        <Box
          component="main"
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
          }}
        >
          <MainImage />
          <Typography id="#aboutMe" sx={{ textAlign: 'center' }} variant="h2">
            {t('aboutMe.title')}
          </Typography>
          <div id="#aboutMe">
            <AboutMe />
          </div>
          <Typography id="#services" sx={{ textAlign: 'center' }} variant="h2">
            {t('services.title')}
          </Typography>
          <ServicesList />
          <Typography id="#gallery" sx={{ textAlign: 'center' }} variant="h2">
            {t('gallery.title')}
          </Typography>
          <Gallery />
          <Typography id="#contactMe" sx={{ textAlign: 'center' }} variant="h2">
            {t('contactMe.title')}
          </Typography>
          <ContactMe />
        </Box>
      </Container>
    </Suspense>
  );
};
