import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box/Box';
import { useTranslation } from 'react-i18next';

const services = [
  {
    title: 'Шопінг супровід',
    price: 100,
    description: 'TBA description',
  },
  // {
  //   title: 'Стилізація фото і відео зйомок',
  //   price: 100,
  //   description: 'TBA description',
  // },
  // {
  //   title: 'Образ для нареченої',
  //   price: 100,
  //   description: 'TBA description',
  // },
  // {
  //   title: 'Розбір типажу та ліній зовнішності',
  //   price: 100,
  //   description: 'TBA description',
  // },
  // {
  //   title: 'Розбір гардеробу',
  //   price: 100,
  //   description: 'TBA description',
  // },
  // {
  //   title: 'термінова порада',
  //   price: 100,
  //   description: 'TBA description',
  // },
  // {
  //   title: 'створення консульного гардеробу',
  //   price: 100,
  //   description: 'TBA description',
  // },
  // {
  //   title: 'стоверення ситуативного образу',
  //   price: 100,
  //   description: 'TBA description',
  // },
];

export default function ServicesAccordion() {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const { t } = useTranslation();
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      {services.map((s, i) => (
        <Accordion
          key={i}
          expanded={expanded === s.title}
          onChange={handleChange(s.title)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ flexShrink: 0 }}>
              {t(`services.services.${i}.title`)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t(`services.services.${i}.description`)}</Typography>
            <Typography>{t(`services.services.${i}.price`)}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
