import TitleImage from '../assets/images/TitleImage.jpg';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import Box from '@mui/material/Box/Box';
import { useTranslation } from 'react-i18next';

const NameTitleStyled = styled(Typography)`
  position: absolute;
  top: 25%;
  color: white;
  text-align: center;
  width: 100%;
`;

const NameSubTitleStyled = styled(NameTitleStyled)`
  top: 45%;
  font-style: italic;
`;

const StyledImage = styled('img')`
  width: 100%;
`;

export function MainImage() {
  const { t } = useTranslation();
  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <StyledImage
          src={TitleImage}
          alt="Me"
          loading="lazy"
          height="auto"
          width="100%"
        />
        <NameTitleStyled
          sx={{
            fontSize: { xs: '2em', sm: '5em' },
            position: { xs: 'relative', sm: 'absolute' },
            // display: { xs: 'none', sm: 'block' },
          }}
        >
          {t('main.title')}
        </NameTitleStyled>
        <NameSubTitleStyled
          sx={{
            position: { xs: 'relative', sm: 'absolute' },
            // display: { xs: 'none', sm: 'block' },
            fontSize: { xs: '1em', sm: '3em' },
          }}
        >
          {t('main.subTitle')}
        </NameSubTitleStyled>
      </Box>
    </Box>
  );
}
