import * as React from 'react';
import GalleryImg1 from '../assets/images/gallery/1.jpg';
import GalleryImg2 from '../assets/images/gallery/2.jpg';
import GalleryImg3 from '../assets/images/gallery/3.jpg';
import GalleryImg4 from '../assets/images/gallery/4.jpg';
import GalleryImg5 from '../assets/images/gallery/5.jpg';
import GalleryImg6 from '../assets/images/gallery/6.jpg';
import GalleryImg7 from '../assets/images/gallery/7.jpg';
import GalleryImg8 from '../assets/images/gallery/8.jpg';
import GalleryImg9 from '../assets/images/gallery/9.jpg';
import ImageGallery from 'react-image-gallery';

export default function Gallery() {
  return (
    <>
      <ImageGallery
        items={itemData.map((i) => ({
          original: i.img,
          thumbnail: i.img,
        }))}
      />
    </>
  );
}

const itemData = [
  {
    img: GalleryImg1,
  },
  {
    img: GalleryImg2,
  },
  {
    img: GalleryImg3,
  },
  {
    img: GalleryImg4,
  },
  {
    img: GalleryImg5,
  },
  {
    img: GalleryImg6,
  },
  {
    img: GalleryImg7,
  },
  {
    img: GalleryImg8,
  },
  {
    img: GalleryImg9,
  },
];
